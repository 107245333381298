import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import { addInfoToCurrentUserAction, userLogsOutAction } from '../redux/actions/actionCreators'

interface IProps {
    children: JSX.Element
}

export default function PrivateRoute({ children }: IProps) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const isLoggedIn = useSelector((state: IReduxStore) => state.user.isLoggedIn)
    const currentUser = useSelector((state: IReduxStore) => state.user.currentUser)

    const fetchMyDetails = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BE_URL}/users/me`, { credentials: 'include' })
            if (response.status === 200) {
                const user = await response.json()
                dispatch(addInfoToCurrentUserAction(user))
            } else if (response.status === 401) {
                const refreshResponse = await fetch(`${process.env.REACT_APP_BE_URL}/users/refresh-token`, { method: 'POST', credentials: 'include' })
                if (refreshResponse.status === 200) {
                    const retryResponse = await fetch(`${process.env.REACT_APP_BE_URL}/users/me`, { credentials: 'include' })
                    if (retryResponse.status === 200) {
                        const user = await retryResponse.json()
                        dispatch(addInfoToCurrentUserAction(user))
                    }
                } else if (refreshResponse.status === 401 || refreshResponse.status === 400) {
                    dispatch(userLogsOutAction())
                    navigate('/login')
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    if (isLoggedIn && !currentUser) fetchMyDetails()

    return isLoggedIn ? children : <Navigate to="/login" />
}