import { Tabs } from "@mantine/core"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router-dom"
import DailyRota from "../components/DailyRota"

export default function WeeklyRota() {

    const { weekNo, dayName } = useParams()
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

    return (
        <>
            <Helmet>
                <title>{`Rota - Week ${weekNo}`}</title>
            </Helmet>
            <Tabs position="center">
                { days.map(day => (
                    <Tabs.Tab key={day} label={day}>
                        <DailyRota day={day} />
                    </Tabs.Tab>
                )) }
            </Tabs>
        </>
    )
}