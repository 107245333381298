import { RangeSlider } from "@mantine/core"
import moment from "moment"
import { useEffect, useState } from "react"

interface IProps {
    location: string
}

export default function SingleLocation({ location }: IProps) {

    const [shifts, setShifts] = useState([''])

    const startTime = moment().utc().set({ hour:0o3, minute:0o0 })
    const endTime = moment().utc().set({ hour:23, minute:0o0 })
    const timeSlots: string[] = []

    while (startTime <= endTime) {
        timeSlots.push(moment(startTime).format('HH:mm'))
        startTime.add(15, 'minutes')
    }

    const fetchShifts = async () => {
        try {
            setShifts(['Hasan Sattar', 'Me Again'])
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        fetchShifts()
    }, [])

    return (
        <>
        <h4 className="font-semibold">{location}</h4>
        <RangeSlider
            size="xs"
            label={null}
            showLabelOnHover={false}
            min={0}
            max={timeSlots.length - 1}
            />   
        </>
    )
}