import { Modal, Stack, TextInput } from "@mantine/core"
import { DatePicker } from "@mantine/dates"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { userLogsOutAction } from "../redux/actions/actionCreators"

export default function WeekCards() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { REACT_APP_BE_URL: BASE_URL } = process.env

    const [weeks, setWeeks] = useState([])
    const [modalOpen, setModalOpen] = useState(false)
    const [newWeekNo, setNewWeekNo] = useState('')
    const [navigateToWeekNo, setNavigateToWeekNo] = useState('')
    const [newWeekStartDate, setNewWeekStartDate] = useState<Date>()
    const [errorMsg, setErrorMsg] = useState('')
    const [loading, setLoading] = useState(false)

    const fetchWeeks = async () => {
        try {
            const response = await fetch(`${BASE_URL}/shifts/get-weeks`, { credentials: 'include' })
            if (response.status === 200) {
                const data = await response.json()
                setWeeks(data)
            } else if (response.status === 401) {
                const refreshResponse = await fetch(`${BASE_URL}/users/refresh-token`, { method: 'POST', credentials: 'include' })
                if (refreshResponse.status === 200) {
                    const retryResponse = await fetch(`${BASE_URL}/shifts/get-weeks`, { credentials: 'include' })
                    if (retryResponse.status === 200) {
                        const data = await retryResponse.json()
                        setWeeks(data)
                    }
                } else if (refreshResponse.status === 401 || refreshResponse.status === 400) {
                    dispatch(userLogsOutAction())
                    navigate('/login')
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleCreateNewWeek = async () => {
        try {
            setErrorMsg('')
            if (!newWeekNo || !newWeekStartDate) setErrorMsg('Please enter a week number and a start date')
            if (Number(newWeekNo) <= 0 || Number(newWeekNo) > 52) setErrorMsg('Please enter a week number between 1 and 52')
            // CHECK DATE IS NOT IN THE PAST
            // CHECK DATE IS ON A SUNDAY
            setLoading(true)

            // TODO: MOVE INTO SUCCESSFUL RESPONSE
            setLoading(false)
            setNavigateToWeekNo(newWeekNo)
            setErrorMsg('')
            setModalOpen(false)
            setNewWeekNo('')
            setNewWeekStartDate(undefined)
            navigate(`/week/${newWeekNo}`)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        fetchWeeks()
    }, [])

    return (
        <>
        <div className="flex justify-center mx-6">
            <button onClick={() => setModalOpen(true)} className="border-blue-500 border hover:bg-blue-500 hover:text-white py-1.5 mt-4 w-full rounded text-blue-500">Add New Week</button>
        </div>
        <Link to="/week/0">
            <p className="capitalize text-center shadow-md hover:shadow-lg p-4 cursor-pointer rounded m-6 mb-8">Week 0</p>
        </Link>
        <div className="grid grid-cols-3 gap-6 m-6">
            { weeks.map(week => (
                <div key={week}>
                    <Link to={`/week/${week}`}>
                        <p className="capitalize text-center shadow-md hover:shadow-lg p-4 cursor-pointer my-2 rounded">Week {week}</p>
                    </Link>
                </div>
            ))}
        </div>
        <Modal
            opened={modalOpen}
            onClose={() => setModalOpen(false)}
            withCloseButton={false}
        >
            <Stack>
                <TextInput
                    label="Week Number"
                    placeholder="1"
                    value={newWeekNo}
                    onChange={e => setNewWeekNo(e.target.value)}
                />
                <DatePicker 
                    label="Week Starting Date" 
                    placeholder="May 6, 2022"
                    firstDayOfWeek="sunday"
                    value={newWeekStartDate}
                    onChange={e => {
                        if (e) setNewWeekStartDate(e)
                    }}
                />
                { errorMsg && <p className="text-red-500 text-center">{errorMsg}</p> }
                <button onClick={handleCreateNewWeek} className="bg-blue-500 hover:bg-blue-600 py-1 w-full rounded text-white">Create</button>
            </Stack>
        </Modal>
        </>
    )
}

// https://www.youtube.com/watch?v=PuJQhtg1VCM
// TODO: ADD ON CHANGE TO DATE PICKER
// TODO: SUBMIT FROM TO BE AFTER DOING SOME VALIDATION + ERROR HANDLING
