import { Helmet } from 'react-helmet-async'
import WeekCards from '../components/WeekCards'

export default function Home() {
    return (
        <>
            <Helmet>
                <title>Smiths Rota - Weeks</title>
            </Helmet>
            <WeekCards />
        </>
    )
}