import { Container, MultiSelect, SimpleGrid, Stack, Textarea } from "@mantine/core"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { userLogsOutAction } from "../redux/actions/actionCreators"
import SVGIcon from "./CustomSVGIcon"
import SingleLocation from "./SingleLocation"

interface IProps {
    day: string
}

export default function DailyRota({ day }: IProps) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { REACT_APP_BE_URL: BASE_URL } = process.env

    const [staffNames, setStaffNames] = useState([''])
    const [locations, setLocations] = useState([''])
    const [published, setPublished] = useState(false)
    const [t13Nights, setT13Nights] = useState<string[]>([])
    const [t2Nights, setT2Nights] = useState<string[]>([])
    const [holidays, setHolidays] = useState<string[]>([])
    const [absent, setAbsent] = useState<string[]>([])

    const fetchStaffNames = async () => {
        try {
            const response = await fetch(`${BASE_URL}/users/all-users`, { credentials: 'include' })
            if (response.status === 200) {
                const users = await response.json()
                setStaffNames(users.map((user: IUser) => `${user.firstName.charAt(0).toUpperCase()}${user.firstName.substr(1)} ${user.lastName.charAt(0).toUpperCase()}${user.lastName.substr(1)}`))
            } else if (response.status === 401) {
                const refreshResponse = await fetch(`${BASE_URL}/users/refresh-token`, { method: 'POST', credentials: 'include' })
                if (refreshResponse.status === 200) {
                    const retryResponse = await fetch(`${BASE_URL}/users/all-users`, { credentials: 'include' })
                    if (retryResponse.status === 200) {
                        const users = await retryResponse.json()
                        setStaffNames(users.map((user: IUser) => `${user.firstName.charAt(0).toUpperCase()}${user.firstName.substr(1)} ${user.lastName.charAt(0).toUpperCase()}${user.lastName.substr(1)}`))
                    }
                } else if (refreshResponse.status === 401 || refreshResponse.status === 400) {
                    dispatch(userLogsOutAction())
                    navigate('/login')
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    const fetDayInfo = async () => {
        try {
            setLocations(['First Hit', 'Main', 'T2 Support'])
            setPublished(true)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        fetchStaffNames()
        fetDayInfo()
    }, [])

    return (
        <Container fluid>
            <Stack>
            <div className="flex justify-between">
                <div className="flex">
                    <SVGIcon svgClassName="h-6 w-6 mr-3" pathD="M10 19l-7-7m0 0l7-7m-7 7h18" />
                    <SVGIcon svgClassName="h-6 w-6 mr-3" pathD="M14 5l7 7m0 0l-7 7m7-7H3" />
                    <SVGIcon svgClassName="h-6 w-6 mr-3" pathD="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
                    { published ? (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                    ) : (
                        <SVGIcon pathD="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                        ) }
                </div>
                <p>{day} 1st January</p>
                <div className="flex">
                    <SVGIcon svgClassName="h-6 w-6 mr-3" pathD="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                    <SVGIcon pathD="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </div>
            </div>
            { locations.map(location => <SingleLocation key={location} location={location} />) }
            <SimpleGrid cols={4}>
                <MultiSelect
                    data={staffNames}
                    value={t13Nights}
                    onChange={e => setT13Nights(e)}
                    label="T1/3 Nights"
                    searchable
                    clearable
                    nothingFound="No Staff Found"
                />
                <MultiSelect
                    data={staffNames}
                    value={t2Nights}
                    onChange={e => setT2Nights(e)}
                    label="T2 Nights"
                    searchable
                    clearable
                    nothingFound="No Staff Found"
                />
                <MultiSelect
                    data={staffNames}
                    value={holidays}
                    onChange={e => setHolidays(e)}
                    label="Holidays"
                    searchable
                    clearable
                    nothingFound="No Staff Found"
                />
                <MultiSelect
                    data={staffNames}
                    value={absent}
                    onChange={e => setAbsent(e)}
                    label="Absent"
                    searchable
                    clearable
                    nothingFound="No Staff Found"
                />
            </SimpleGrid>
            <Textarea
                placeholder="Notes"
                autosize
                minRows={1}
            />
            </Stack>
        </Container>
    )
}