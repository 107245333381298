import { FormEvent, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { addInfoToCurrentUserAction, userLogsInAction } from '../redux/actions/actionCreators'

const Login = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userLoggedIn = useSelector((state: IReduxStore) => state.user.isLoggedIn)
    const { REACT_APP_BE_URL } = process.env

    const [usernameError, setUsernameError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [loginLoading, setLoginLoading] = useState(false)
    const [userDetails, setUserDetails] = useState({
        username: '',
        password: ''
    })

    const handleChange = (field: string, value: string) => {
        setUserDetails(details => ({
            ...details,
            [field]: value
        }))
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()

        setUsernameError(false)
        setPasswordError(false)
        setErrorMessage('')

        if (!userDetails.username && !userDetails.password) {
            setUsernameError(true)
            setPasswordError(true)
            setErrorMessage('Enter Username & Password')
            return
        }

        if (!userDetails.username) {
            setUsernameError(true)
            setErrorMessage('Enter Username')
            return
        }
        
        if (!userDetails.password) {
            setPasswordError(true)
            setErrorMessage('Enter Password')
            return
        }

        setLoginLoading(true)   

        try {
            const response = await fetch(`${REACT_APP_BE_URL}/users/admin-login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userDetails),
                credentials: 'include'
            })
            if (response.status === 200) {
                const user = await response.json()
                dispatch(userLogsInAction())
                dispatch(addInfoToCurrentUserAction(user))
                setLoginLoading(false)
                navigate('/')
            } else if (response.status === 401) {
                setErrorMessage('Invalid Login')
                setLoginLoading(false)
            } else if (response.status === 403) {
                setErrorMessage('You are not authorized to access this application')
                setLoginLoading(false)
            }
        } catch (error) {
            console.error(error)
            setLoginLoading(false)
        }
    }

    if (userLoggedIn) return <Navigate to="/" />

    return (
        <>
            <Helmet>
                <title>Smiths Rota - Login</title>
            </Helmet>
            <div className="max-w-[75vw] mx-auto flex flex-col justify-evenly h-[100vh] dark:bg-gray-800">
            <h2 className="text-blue-500 text-6xl text-center">Smiths Rota</h2>
            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                    <div className="relative z-0 mb-6 w-full group">
                        <input 
                            type="email" 
                            name="floating_email" 
                            className={ usernameError ? "block py-2.5 px-0 w-full text-sm text-gray-900 dark:text-white bg-transparent border-0 border-b-2 border-red-500 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 dark:focus:border-blue-500 peer" : "block py-2.5 px-0 w-full text-sm text-gray-900 dark:text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 dark:focus:border-blue-500 peer"} 
                            required 
                            value={userDetails.username}
                            onChange={e => handleChange('username', e.target.value)}
                        />
                        <label className={usernameError ? "absolute text-sm text-red-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 dark:peer-focus:border-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6" : "absolute text-sm text-gray-500 dark:text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 dark:peer-focus:border-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"}>Username</label>
                    </div>
                    <div className="relative z-0 mb-6 w-full group">
                        <input 
                            type="password" 
                            name="floating_password" 
                            className={ passwordError ? "block py-2.5 px-0 w-full text-sm text-gray-900 dark:text-white bg-transparent border-0 border-b-2 border-red-500 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 dark:focus:border-blue-500 peer" : "block py-2.5 px-0 w-full text-sm text-gray-900 dark:text-white bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 dark:focus:border-blue-500 peer"} 
                            required 
                            value={userDetails.password}
                            onChange={e => handleChange('password', e.target.value)}
                        />
                        <label className={passwordError ? "absolute text-sm text-red-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 dark:peer-focus:border-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6" : "absolute text-sm text-gray-500 dark:text-white duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 dark:peer-focus:border-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"}>Password</label>
                    </div>
                    { errorMessage && <p className="text-red-500 text-center mb-4">{errorMessage}</p> }
                    <button type="submit" className="transition duration-200 bg-blue-500 hover:bg-blue-600 focus:bg-blue-700 focus:shadow-sm focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white w-full py-2.5 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block">
                        { loginLoading ? 'Verifying...' : 'Login' }
                    </button>
                </form>
            </div>
        </>
    )
} 

export default Login