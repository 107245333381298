import { applyMiddleware, combineReducers, compose, createStore } from "redux"
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { encryptTransform } from 'redux-persist-transform-encrypt'
import userReducer from "./reducers/userReducer"

let composeSafely = compose

if (typeof window !== 'undefined') {
    composeSafely = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
}

export const initialState: IReduxStore = {
    user: {
        isLoggedIn: false,
        currentUser: null
    }
}

const persistConfig: any = {
    key: 'root',
    storage,
    transforms: [
        encryptTransform({
            secretKey: process.env.REACT_APP_SECRET_KEY || '',
            onError: (err: any) => console.log(err)
        })
    ]
}

const rootReducer = combineReducers({ 
    user: userReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const storeConfig = createStore(persistedReducer, initialState, composeSafely(applyMiddleware(thunk)))

export const persistor = persistStore(storeConfig)