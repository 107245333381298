import { useEffect, useState, useRef } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Drawer, Button, Group } from '@mantine/core'
import { userLogsOutAction } from '../redux/actions/actionCreators'
import SVGIcon from './CustomSVGIcon'

export default function Navbar() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const { websiteName } = useParams()
    const userMenuRef = useRef<HTMLDivElement>(null)
    const currentUser = useSelector((state: IReduxStore) => state.user.currentUser)
        
    const [showUserMenu, setShowUserMenu] = useState(false)
    const [opened, setOpened] = useState(false)
    
    const isHome = pathname === '/'
    
    const handleLogout = () => {
      setShowUserMenu(false)
      dispatch(userLogsOutAction())
      navigate('/login')
    }

    useEffect(() => {
      const checkIfMouseClickedOutsideUserMenu = (e: MouseEvent) => {
          if (showUserMenu && userMenuRef.current && !userMenuRef.current.contains(e.target as Node)) {
              setShowUserMenu(false)
          }
      }

      document.addEventListener('mousedown', checkIfMouseClickedOutsideUserMenu)

      return () => document.removeEventListener('mousedown', checkIfMouseClickedOutsideUserMenu)
    }, [showUserMenu])

    if (!currentUser) return <></>

    const { firstName, lastName } = currentUser

    if (pathname === '/login') return <></>

    if (pathname === '/' || pathname.startsWith('/week/')) return (
      <>
        <div className="relative select-none">
        <nav className="bg-blue-500 text-white border-gray-200 px-2 sm:px-4 py-2">
        <div className="flex flex-wrap justify-between items-center mx-auto">
          <div className="flex items-center">
            <span onClick={() => setOpened(true)}>
              <SVGIcon pathD="M4 6h16M4 12h16M4 18h16" svgClassName={isHome ? "text-white h-6 w-6 font-bold mr-3 cursor-pointer" : "text-white h-6 w-6 font-bold mr-3 cursor-pointer"} />
            </span>
            <Link to="/" className="flex"><span className={isHome ? "self-center text-lg whitespace-nowrap" : "self-center text-lg whitespace-nowrap"}>Smiths Rota</span></Link>
          </div>
            <div className="flex items-center">
                <button  onClick={() => setShowUserMenu(prev => !prev)} className="flex mr-3 text-sm rounded-full md:mr-0">
                    <img className={isHome ? "w-8 h-8 rounded-full" : "w-8 h-8 rounded-full" } src={`https://ui-avatars.com/api/?name=${firstName}+${lastName}&background=fff&color=000`} alt={`${firstName} ${lastName}`} />
                </button>
            </div>
            </div>
        </nav>
        <div className="absolute right-3 z-50" ref={userMenuRef}>
            <div className={`${!showUserMenu && 'hidden'} z-50 my-1 text-base list-none bg-white rounded divide-y divide-gray-100 shadow-md w-40`}>
                <div className="py-3 px-4">
                  <span className="block text-sm capitalize text-gray-900">{`${firstName} ${lastName}`}</span>
                </div>
                <ul className="my-1">
                <li>
                    <button onClick={handleLogout} className="flex w-full text-left py-2 px-4 text-sm text-red-400 hover:bg-gray-100">
                        <SVGIcon pathD="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                        <span className="ml-2">Logout</span>
                    </button>
                </li>
                </ul>
            </div>
        </div>
        </div>
        <Drawer
          opened={opened}
          onClose={() => setOpened(false)}
          padding="xl"
          size="md"
          withCloseButton={false}
        >
          {/* Drawer content */}
        </Drawer>
      </>
    )

    return <></>
}