import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Navbar from './components/Navbar'
import Home from './pages/Home'
import Login from "./pages/Login"
import PrivateRoute from "./pages/PrivateRoute"
import WeeklyRota from "./pages/WeeklyRota"
import NotFound from './pages/NotFound'

export default function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' element={ <PrivateRoute><Home /></PrivateRoute> } />
        <Route path='/login' element={ <Login /> } />
        <Route path='/week/:weekNo' element={ <WeeklyRota /> } />
        <Route path='*' element={ <NotFound /> } />
      </Routes>
    </Router>
  )
}